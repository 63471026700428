import { HStack, Tag } from "@chakra-ui/react";
import React from "react";
import stripHtml from "string-strip-html";

import * as CustomFieldTypeID from "../../../../shared/v2/constants/CustomFieldTypeID";
import {
  CustomFieldRecord,
  CustomFieldType,
} from "../../../../shared/v2/definitions/customFields";
import assertNever from "../../../../utilities/assertNever";
import { formatCustomFieldDateForRender } from "../../../../utilities/dateUtils";
import TableDescriptionField from "./TableDescriptionField";

export function getFieldDisplayValue(
  customFieldData: CustomFieldRecord,
  customField: CustomFieldType,
  asPlainText: true,
): string;
export function getFieldDisplayValue(
  customFieldData: CustomFieldRecord,
  customField: CustomFieldType,
  asPlainText?: false,
): React.ReactNode;
export default function getFieldDisplayValue(
  customFieldData: CustomFieldRecord,
  customField: CustomFieldType,
  asPlainText?: boolean,
) {
  if (
    !customFieldData ||
    !customField ||
    customFieldData.value === null ||
    customFieldData.value === undefined
  ) {
    return asPlainText ? "" : null;
  }
  switch (customFieldData.typeID) {
    case CustomFieldTypeID.Date:
      return formatCustomFieldDateForRender(customFieldData.value);

    case CustomFieldTypeID.Text:
      return customFieldData.value;

    case CustomFieldTypeID.LongText:
      return asPlainText ? (
        stripHtml(customFieldData.value)
      ) : (
        <TableDescriptionField description={customFieldData.value} />
      );

    case CustomFieldTypeID.Number:
      // Weve already checked if its null above so can just put in a string
      return `${customFieldData.value}`;

    case CustomFieldTypeID.SingleSelectList:
    case CustomFieldTypeID.MultiselectList: {
      // Filter out null and undefined as an otherwise empty multiselect will usually include a null
      const filteredValue = customFieldData.value.filter(
        (item) => item !== null && item !== undefined && item.valueID !== null,
      );
      if (asPlainText) {
        if (filteredValue.length <= 0) return "";
        return filteredValue.map((item) => item.valueLiteral).join(", ");
      }
      // Not plaintext
      if (filteredValue.length <= 0) return null;
      const mappedValues = filteredValue.map((item) => {
        return (
          <Tag key={item.valueID} variant="outline">
            {item.valueLiteral}
          </Tag>
        );
      });
      return <HStack as="span">{mappedValues}</HStack>;
    }

    case CustomFieldTypeID.Link:
      return `${customFieldData.value}`;

    case CustomFieldTypeID.Upload:
    case CustomFieldTypeID.OnedriveUpload:
      return customFieldData.value === null || customFieldData.value === ""
        ? "(No Upload)"
        : "(Uploaded)";

    default:
      try {
        assertNever(customFieldData);
      } catch (e) {
        console.error(
          `Unknown customFieldTypeID in table: ${
            (customFieldData as CustomFieldRecord).typeID
          }`,
        );
        return asPlainText ? "" : null;
      }
  }
  return asPlainText ? "" : null;
}
