import {
  Badge,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Icon,
  Tooltip,
  Alert,
  CloseButton,
  AlertIcon,
  LinkBox,
  Popover,
  Divider,
  PopoverTrigger,
  Text,
  Link,
  PopoverContent,
  VStack,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  LinkOverlay,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { identity } from "lodash";
import { useEffect, useMemo, useState } from "react";
import stripHtml from "string-strip-html";

import {
  RiArrowDownSLine,
  RiContractUpDownLine,
  RiLayoutColumnLine,
  RiListSettingsFill,
} from "react-icons/ri";
import { Link as BrowserLink } from "react-router-dom";
import {
  DropdownIcon,
  ExportIcon,
  RefreshIcon,
} from "../../../../constants/commonIcons";
import * as CustomFieldTypeID from "../../../../shared/v2/constants/CustomFieldTypeID";
import { CustomFieldType } from "../../../../shared/v2/definitions/customFields";
import { RecordSummary } from "../../../../shared/v2/definitions/record";
import { RegisterPage } from "../../../../shared/v2/definitions/registers";
import { useApiQuery } from "../../../../utilities/apibelRequest";
import {
  formatDateForFileName,
  formatDateOrIsoString,
  formatFullDateOrIsoStringInTz,
} from "../../../../utilities/dateUtils";
import { downloadAsCsv } from "../../../../utilities/downloadAsCsv";
import Select from "../../../Select";
import ObjectStatusBadge from "../../../UI/ObjectStatusBadge";
import RecordsViewFilters from "./RecordsViewFilters";
import { Column } from "./RecordsViewTypes";
import TableCustomField from "./TableCustomField";
import getFieldDisplayValue from "./getFieldDisplayValue";
import TableDescriptionField from "./TableDescriptionField";
import TableResponsibleUserField from "./TableResponsibleUserField";
import useRecordsViewState, { SortOptions } from "./useRecordsViewState";
import {
  FilterOp,
  FilterSet,
} from "../../../../shared/v2/helpers/filters/filterSchemas";
import DateView from "../DateView";
import { useQueryClient } from "react-query";
import useToast from "../../../../utilities/useToast";

const DensityOptions = [
  {
    value: "sm",
    label: "Compact",
  },
  {
    value: "md",
    label: "Comfortable",
  },
] as const;
type DensityOption = (typeof DensityOptions)[number];

type Props = {
  pageDefinition?: RegisterPage;
  objectTypeID: string;
};

const CoreColumns = [
  {
    label: "Description",
    ID: "description",
    isNumeric: false,
    order: 0,
  },
  {
    label: "User",
    ID: "responsibleUser",
    isNumeric: false,
    order: 1,
  },
  {
    label: "Status",
    ID: "status",
    isNumeric: false,
    order: 2,
  },
  {
    label: "Create Time",
    ID: "createTs",
    isNumeric: false,
    order: 3,
  },
  {
    label: "Updated Time",
    ID: "modifiedTs",
    isNumeric: false,
    order: 4,
  },
];

const customFieldToColumn = (cf: CustomFieldType, idx: number) => ({
  label: cf.label,
  ID: cf.customFieldID,
  isNumeric: cf.customFieldTypeID === CustomFieldTypeID.Number,
  order: idx + CoreColumns.length,
});

function getColumns(page: RegisterPage | undefined): {
  allColumns: Column[];
  defaultColumns: Column[];
} {
  if (!page) {
    return { allColumns: [], defaultColumns: [] };
  }
  const allColumns = [
    ...CoreColumns,
    ...page.customFields.map(customFieldToColumn),
  ];
  const defaultColumns = [
    ...CoreColumns.filter((c) => c.ID !== "createTs"), // Always display all core columns by default (other than the create timestamp)
    ...page.customFields
      .filter((cf) => cf.inRegisterView)
      .map(customFieldToColumn),
  ];
  return { allColumns, defaultColumns };
}

/**
 * Displays a table of records.
 *
 * The only column that is static and required is 'name'. The name column renders both the readableID and the name of the record.
 */
export default function RecordTable({ pageDefinition, objectTypeID }: Props) {
  const { allColumns, defaultColumns } = useMemo(() => {
    return getColumns(pageDefinition);
  }, [pageDefinition]);

  const [displayAlert, setDisplayAlert] = useState(true);

  const queryClient = useQueryClient();

  const [state, dispatch] = useRecordsViewState();

  const { displayToast } = useToast();

  // Transform filters for use on the backend
  const filter: FilterSet | null = useMemo(() => {
    if (state.filters.length <= 0) {
      return null;
    }
    const filterObj: FilterSet = {
      type: "and",
      filters: state.filters.map((f) => {
        const value = Array.isArray(f.value)
          ? f.value.map((fv) => fv.value)
          : f.value?.value;
        const op = f.op.op as FilterOp;
        return {
          op,
          value,
          field: f.field.identifier,
        };
      }),
    };
    return filterObj;
  }, [state]);

  const { page, resultsPerPage } = state;

  const getRecordsQuery = useApiQuery(
    "record/many",
    {
      query: state.query,
      objectTypeID: objectTypeID || "null",
      pagination: {
        page,
        resultsPerPage,
      },
      orderBy: state.sortBy.value || "name",
      filter,
    },
    {
      enabled: !!objectTypeID,
      keepPreviousData: true,
    },
  );

  const handleClickRefresh = () => {
    queryClient.invalidateQueries(["record", "many"]);
  };

  const [selectedColumns, setSelectedColumns] = useState<Column[]>(
    [...defaultColumns].sort((a, b) => a.order - b.order),
  );

  useEffect(() => {
    setSelectedColumns([...defaultColumns].sort((a, b) => a.order - b.order));
  }, [defaultColumns]);

  useEffect(() => {
    if (pageDefinition) {
      dispatch({ type: "reset" });
    }
  }, [pageDefinition, dispatch]);

  const [density, setDensity] = useState<DensityOption>({
    value: "sm",
    label: "Compact",
  });

  const handleClickExport = () => {
    if (getRecordsQuery.isSuccess && pageDefinition) {
      const filename = `${formatDateForFileName(new Date())}-records.csv`;
      const mappedRecords = getRecordsQuery.data.records.map(
        ({
          name,
          description,
          responsibleUsers,
          readableID,
          modifiedTs,
          createTs,
          customFields,
        }) => {
          const cfs = Object.values(customFields).reduce((acc, cf) => {
            const customFieldType = pageDefinition.customFields.find(
              (c) => c.customFieldID === cf.ID,
            );
            return {
              ...acc,
              [cf.label]: customFieldType
                ? `${getFieldDisplayValue(cf, customFieldType, true)}${
                    customFieldType.unitLabel ?? ""
                  }`
                : "[error]",
            };
          }, {});
          return {
            ID: readableID,
            Name: name,
            Description: stripHtml(description || ""),
            "Responsible User": `${responsibleUsers[0]?.firstName} ${responsibleUsers[0]?.lastName}`,
            "Created At": createTs,
            "Updated At": modifiedTs,
            ...cfs,
          };
        },
      );
      downloadAsCsv(mappedRecords, filename);
      displayToast({
        title: "Exported to CSV successfully",
        status: "success",
      });
    }
  };

  const renderData = (record: RecordSummary, column: Column) => {
    if (!pageDefinition) return null;
    switch (column.ID) {
      case "description":
        return <TableDescriptionField description={record.description} />;
      case "responsibleUser":
        return (
          <TableResponsibleUserField
            size={density.value === "md" ? "sm" : "xs"}
            responsibleUsers={record.responsibleUsers}
          />
        );
      case "status":
        return <ObjectStatusBadge statusID={record.statusID} />;
      case "createTs":
        return <DateView date={record.createTs} />;
      case "modifiedTs":
        return <DateView date={record.modifiedTs} />;
      default: {
        const customField = pageDefinition.customFields.find(
          (cf) => cf.customFieldID === column.ID,
        );
        const customFieldData = record.customFields[column.ID];
        return (
          <TableCustomField
            customField={customField}
            customFieldData={customFieldData}
          />
        );
      }
    }
  };

  const isLoading = !pageDefinition || getRecordsQuery.isLoading;
  const isFetching = !pageDefinition || getRecordsQuery.isFetching;

  return (
    <>
      <HStack align="end" justify="space-between">
        <RecordsViewFilters
          state={state}
          dispatch={dispatch}
          customFields={pageDefinition?.customFields}
          lists={pageDefinition?.lists}
          isFetching={isFetching}
        />
        <VStack align="end">
          {displayAlert && (
            <Alert maxW="xl" status="info">
              <AlertIcon />
              <VStack align="start" spacing="0">
                <span>Welcome to the enhanced register records dashboard.</span>
                <span>
                  If you need to access the legacy dashboard,{" "}
                  <Link
                    color="blue.700"
                    to={`/register/legacy-dashboard?filter=${objectTypeID}&display=registers`}
                    as={BrowserLink}>
                    click here.
                  </Link>
                </span>
              </VStack>
              <CloseButton
                alignSelf="flex-start"
                position="relative"
                right={-1}
                top={-1}
                onClick={() => setDisplayAlert(false)}
              />
            </Alert>
          )}
          <ButtonGroup variant="outline" size="sm" isAttached>
            <IconButton
              aria-label="Refresh"
              icon={<Icon boxSize={4} as={RefreshIcon} />}
              onClick={handleClickRefresh}
              isDisabled={isLoading}
              isLoading={isFetching}
            />
            <Popover isLazy computePositionOnMount>
              <PopoverTrigger>
                <IconButton
                  aria-label="Export"
                  icon={<Icon boxSize={4} as={ExportIcon} />}
                  isDisabled={isFetching}
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Export as CSV</PopoverHeader>
                <PopoverBody>
                  <VStack w="full" align="end">
                    <Text size="sm">
                      Export this table as a CSV file. Only rows currently
                      visible will be exported.
                    </Text>
                    <Button
                      variant="solid"
                      size="sm"
                      isDisabled={isFetching}
                      onClick={handleClickExport}>
                      Export
                    </Button>
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Popover isLazy computePositionOnMount>
              <PopoverTrigger>
                <Button
                  leftIcon={<Icon boxSize={4} as={RiListSettingsFill} />}
                  rightIcon={<Icon boxSize={4} as={DropdownIcon} />}>
                  Display
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Display Config</PopoverHeader>
                <PopoverBody>
                  <VStack w="full">
                    <Flex w="full">
                      <FormControl flex="1" mr="1">
                        <FormLabel>Density</FormLabel>
                        <Select
                          w="full"
                          singleOptionsDisplayMode="radio"
                          placeholderText="Density"
                          value={density}
                          onChange={(newVal) => {
                            if (newVal) setDensity(newVal);
                          }}
                          options={[...DensityOptions]}
                        />
                      </FormControl>
                      <FormControl flex="1">
                        <FormLabel>Sort By</FormLabel>
                        <Select
                          w="full"
                          singleOptionsDisplayMode="radio"
                          placeholderText="Sort by"
                          value={state.sortBy}
                          onChange={(newVal) => {
                            if (newVal)
                              dispatch({
                                type: "updateSortBy",
                                sortBy: newVal,
                              });
                          }}
                          options={[...SortOptions]}
                        />
                      </FormControl>
                    </Flex>
                    <Divider />
                    <FormControl>
                      <FormLabel>Show/Hide Columns</FormLabel>
                      <Select
                        multiselect
                        type="button-group"
                        placeholderText="Columns"
                        simpleButtonProps={{
                          isDisabled: isFetching,
                          leftIcon: (
                            <Icon boxSize={4} as={RiLayoutColumnLine} />
                          ),
                        }}
                        getKey={(option) => option.ID}
                        getLabel={(option) => option.label}
                        value={selectedColumns}
                        onChange={(newVal) =>
                          setSelectedColumns(
                            [...newVal].sort((a, b) => a.order - b.order),
                          )
                        }
                        options={allColumns}
                      />
                    </FormControl>
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </ButtonGroup>
        </VStack>
      </HStack>
      <TableContainer w="full" py="1">
        <Table
          variant="data-table"
          isFetching={isFetching}
          size={density.value}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              {selectedColumns.map((column) => (
                <Th key={column.ID} isNumeric={column.isNumeric}>
                  {column.label}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {!isLoading &&
              getRecordsQuery.isSuccess &&
              getRecordsQuery.data.records.map((record) => (
                <Tr key={record.ID}>
                  <LinkBox
                    _hover={{ "> a": { textDecoration: "underline" } }}
                    as={Td}>
                    <Flex as="span" h="full" w="full">
                      <Badge mr="1" colorScheme="gray">
                        {record.readableID}
                      </Badge>
                      <LinkOverlay
                        to={`/register/${record.ID}`}
                        as={BrowserLink}>
                        {record.name}
                      </LinkOverlay>
                    </Flex>
                  </LinkBox>
                  {selectedColumns.map((column) => (
                    <Td key={column.ID} isNumeric={column.isNumeric}>
                      {renderData(record, column)}
                    </Td>
                  ))}
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      {getRecordsQuery.isSuccess && (
        <HStack
          w="full"
          h="full"
          py="2"
          borderTopWidth="2px"
          justify="end"
          align="center"
          position="relative">
          <Select
            type="simple-button"
            simpleButtonProps={{
              size: "sm",
              variant: "outline",
              rightIcon: <Icon as={RiArrowDownSLine} />,
            }}
            placeholderText={`${resultsPerPage} per page`}
            singleOptionsDisplayMode="radio"
            getKey={identity}
            getLabel={identity}
            options={[30, 60, 100]}
            value={resultsPerPage}
            onChange={(newVal) =>
              dispatch({
                type: "updateResultsPerPage",
                resultsPerPage: newVal || 30,
              })
            }
          />
          <ButtonGroup size="sm" variant="outline" isAttached>
            <Button
              isDisabled={getRecordsQuery.data.pagination.prev === undefined}
              onClick={() =>
                dispatch({
                  type: "updatePage",
                  page: getRecordsQuery.data.pagination.page - 1,
                })
              }>
              Prev
            </Button>
            <Button>{getRecordsQuery.data.pagination.page + 1}</Button>
            <Button
              isDisabled={getRecordsQuery.data.pagination.next === undefined}
              onClick={() =>
                dispatch({
                  type: "updatePage",
                  page: getRecordsQuery.data.pagination.page + 1,
                })
              }>
              Next
            </Button>
          </ButtonGroup>
        </HStack>
      )}
    </>
  );
}
