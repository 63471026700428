import { Formik, yupToFormErrors } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card as Card2,
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Copy, Edit2, Frown } from "react-feather";
import { useLocation, useNavigate, useParams } from "react-router";
import stripHtml from "string-strip-html";
import * as Yup from "yup";
import { useAppState } from "../../components/App/AppProvider";
import * as LocalStorage from "../../components/App/localStorage";
import { MultiSelect } from "../../components/Forms/Field/MultiSelect";
import { Select } from "../../components/Forms/Field/Select";
import { SpinnyButton } from "../../components/Forms/Field/SpinnyButton";
import { Text } from "../../components/Forms/Field/Text";
import { Textarea } from "../../components/Forms/Field/Textarea";
import { SettingsFilters } from "../../components/Forms/SettingsFilters/SettingsFilters";
import { CollapseChevron } from "../../components/UI/CollapseChevron/CollapseChevron";
import { Dialog } from "../../components/UI/Dialog/Dialog";
import { Header } from "../../components/UI/Header/Header";
import { History } from "../../components/UI/History/History";
import LegacyScreenContainer from "../../components/UI/LegacyScreenContainer";
import { Loading } from "../../components/UI/Loading/Loading";
import * as CoreFields from "../../shared/constants/coreFields";
import * as ObjectTypeGroup from "../../shared/constants/objectTypeGroup";
import * as Permissions from "../../shared/constants/permission";
import { GeneratedReportRow } from "../../shared/definitions/reports";
import * as Access from "../../utilities/access";
import { validateDate } from "../../utilities/formatDate";
import {
  del,
  get,
  getPaginated,
  post,
  put,
  RequestStatus,
} from "../../utilities/request";
import { isValidEmail } from "../../utilities/strings";
import { getUrlSearchParam } from "../../utilities/url";
import useToast, { DisplayToastFn } from "../../utilities/useToast";
import {
  combineReportResults,
  displayFilters,
  displayReport,
  findNotNull,
  formatCellValue,
  formatColumnFieldsFromDB,
  groupReport,
  sortReport,
} from "./Common";
import {
  GeneratedReportPageData,
  ReportPageData,
  ReportPageStatus,
} from "./Report.d";
import { ScheduleReport } from "./ScheduleReport";

const validationSchema: any = Yup.object().shape({
  ObjectTypeID: Yup.string().required("Report type is required."),
  ReportName: Yup.string().required("Name is required."),
  ReportResponsibleUserID: Yup.string().required(
    "Responsible user is required.",
  ),
});

const requirementColumnGroups = [
  {
    title: "Subject",
    level: 0,
  },
  {
    title: "Parent",
    level: 1,
  },
  {
    title: "Child",
    level: -1,
  },
  {
    title: "Action",
    level: -2,
  },
];
const registerJobColumnGroups = [
  {
    title: "Subject",
    level: 0,
  },
  {
    title: "Action",
    level: -2,
  },
];
const actionColumnGroups = [
  {
    title: "Subject",
    level: 0,
  },
  {
    title: "Parent",
    level: 1,
  },
  {
    title: "Grand Parent",
    level: 2,
  },
];

const filterFieldsToHeadings = (
  group: string,
  field: any,
  objectTypeGroupID: string,
) => {
  if (objectTypeGroupID === ObjectTypeGroup.Action) {
    if (group === "Subject") {
      return field.ObjectTypes.some((objectType: any) =>
        objectType.ObjectTypeGroupID
          ? objectType.ObjectTypeGroupID === ObjectTypeGroup.Action
          : objectType.ObjectTypeID === ObjectTypeGroup.Action,
      );
    }
    return field.ObjectTypes.some((objectType: any) =>
      objectType.ObjectTypeGroupID
        ? objectType.ObjectTypeGroupID !== ObjectTypeGroup.Action
        : objectType.ObjectTypeID !== ObjectTypeGroup.Action,
    );
  }
  if (group === "Action") {
    return field.ObjectTypes.some((objectType: any) =>
      objectType.ObjectTypeGroupID
        ? objectType.ObjectTypeGroupID === ObjectTypeGroup.Action
        : objectType.ObjectTypeID === ObjectTypeGroup.Action,
    );
  }
  return field.ObjectTypes.some((objectType: any) =>
    objectType.ObjectTypeGroupID
      ? objectType.ObjectTypeGroupID === objectTypeGroupID
      : objectType.ObjectTypeID === objectTypeGroupID,
  );
};

const formatFields = (
  customFields: any[],
  coreFields: any[],
  objectTypeGroupID: any,
) => {
  const objectTypeGroupIDKey: keyof typeof ObjectTypeGroup.ObjectTypeGroupIDToName =
    objectTypeGroupID;
  const groupHeadings =
    objectTypeGroupID === ObjectTypeGroup.Action
      ? actionColumnGroups
      : objectTypeGroupID === ObjectTypeGroup.Register ||
        objectTypeGroupID === ObjectTypeGroup.Job
      ? registerJobColumnGroups
      : requirementColumnGroups;
  const columnOptions = groupHeadings.map((group) => ({
    value: group.title.toLowerCase(),
    label: `${
      group.title === "Subject"
        ? ObjectTypeGroup.ObjectTypeGroupIDToName[objectTypeGroupIDKey]
        : group.title
    } Record`,
    options: coreFields
      .filter((coreField: any) =>
        filterFieldsToHeadings(group.title, coreField, objectTypeGroupID),
      )
      .map((coreField: any) => ({
        ID: coreField.CoreFieldID,
        Name: `${group.title}: ${coreField.CoreFieldLabel}`,
        Type: "Core",
        CustomFieldTypeColumnName: coreField.CustomFieldTypeColumnName,
        Level: group.level,
        value: `${group.level}_${coreField.CoreFieldID}`,
        label: `${
          group.title === "Subject"
            ? ObjectTypeGroup.ObjectTypeGroupIDToName[objectTypeGroupIDKey]
            : group.title
        }: ${coreField.CoreFieldLabel}`,
        isFixed: group.title === "Subject" && coreField.CoreFieldLabel === "ID",
      }))
      .concat(
        customFields
          .filter((customField: any) =>
            filterFieldsToHeadings(group.title, customField, objectTypeGroupID),
          )
          .map((customField: any) => ({
            ID: customField.CustomFieldID,
            Name: `${group.title}: ${customField.CustomFieldLabel}`,
            Type: "Custom",
            CustomFieldTypeColumnName: customField.CustomFieldTypeColumnName,
            Level: group.level,
            value: `${group.level}_${customField.CustomFieldID}`,
            label: `${
              group.title === "Subject"
                ? ObjectTypeGroup.ObjectTypeGroupIDToName[objectTypeGroupIDKey]
                : group.title
            }: ${customField.CustomFieldLabel}`,
            isFixed: false,
          })),
      )
      .sort((a: any, b: any) =>
        a.Name.localeCompare(b.Name, "en", { numeric: true }),
      ),
  }));
  return columnOptions;
};

const valideFilters = (filters: any) => {
  let check = true;
  if (filters.length > 0) {
    filters.forEach((f: any) => {
      if (f.FieldID !== "") {
        if (f.ReportFilterValueOperator === "") {
          check = false;
        }
        if (
          f.ReportFilterValueOperator !== "exists" &&
          f.ReportFilterValueDATETIME === "" &&
          f.ReportFilterValueVARCHAR === "" &&
          f.ReportFilterValueINT === "" &&
          f.ReportFilterValueURL === "" &&
          f.ListValueID === "" &&
          f.DocumentID === ""
        ) {
          check = false;
        }
      }
    });
  }
  return check;
};

const getVisibilityOptions = (data: any, responsibleUser: string) => {
  const visibilityOptions: any[] = [];
  data.Users.forEach((u: any) => {
    if (u.UserID !== responsibleUser) {
      visibilityOptions.push({
        ID: u.UserID,
        Name: `${u.UserFirstName} ${u.UserLastName}`,
        Type: "User",
      });
    }
  });
  data.Roles.forEach((r: any) => {
    visibilityOptions.push({
      ID: r.RoleID,
      Name: `${r.RoleName} (Role)`,
      Type: "Role",
    });
  });
  return visibilityOptions;
};

const checkUser = (Id: string, data: any) => {
  let check = false;
  if (data && data.VisibilityIDs) {
    if (data.ReportResponsibleUserID === Id) {
      check = true;
    } else if (data.VisibilityIDs) {
      data.VisibilityIDs.forEach((vid: any) => {
        if (vid.ID === Id) {
          check = true;
        }
      });
    }
    // we have yet to check the roles so lets do that
    if (check === false) {
      // get the info of the user from the users info
      const currentUserInfo = data.Users.filter((u: any) => u.UserID === Id);
      data.VisibilityIDs.forEach((vid: any) => {
        currentUserInfo[0].Roles.forEach((r: any) => {
          if (vid.ID === r.RoleID) {
            check = true;
          }
        });
      });
    }
  }
  return check;
};

const ScreensReport = () => {
  const { app, auth } = useAppState();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { displayToast } = useToast();
  const [pageStatus, setPageStatus] = useState<ReportPageStatus>("Loading");
  const [data, setData] = useState<ReportPageData>(null);
  const [generatedData, setGeneratedData] =
    useState<GeneratedReportPageData>(null);
  const [display, setDisplay] = useState<string>("");
  const [isCopyMode, setCopyMode] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [generatedDataIsCollapsed, updateGeneratedDataIsCollapsed] =
    useState<boolean>(false);
  const [scheduleIsCollapsed, updateScheduleIsCollapsed] =
    useState<boolean>(false);

  const reportID = params.reportID || "";

  const deleteReport = async (data: any) => {
    const showDeleteToast = (success: boolean) => {
      displayToast({
        status: success ? "success" : "error",
        title: success ? `Report deleted successfully` : `Report delete failed`,
      });
    };

    const redirect = () => {
      navigate("/report");
    };

    try {
      // remove the local storage after cancel
      window.localStorage.removeItem(`${window.location.href}`);

      const deleteResults = await del(`report/${reportID}`, auth);
      showDeleteToast(deleteResults.status === 200);
      window.setTimeout(redirect, 3000);
    } catch {
      showDeleteToast(false);
      window.setTimeout(redirect, 3000);
    }
  };

  // collapse
  const [isDetailsCollapsed, updateDetailsCollapsed] = useState<boolean>(false);

  useEffect(() => {
    const fetchReport = async () => {
      setPageStatus("Loading");
      setGeneratedData(null);
      updateDetailsCollapsed(false);
      const result = await get(`report/${reportID}${location.search}`, auth);
      if (result.status !== 200 || !result.data) {
        displayToast({
          status: "erorr",
          title: "Failed to retrieve Report",
        });
      } else if (result.data.ReportID === "NotExist") {
        // display error
        setPageStatus("Error");
      } else {
        // proceed
        if (
          reportID === "new" &&
          result.data.Users.find(
            (user: any) => user.UserID === app.attributes.userID,
          )
        ) {
          result.data.ReportResponsibleUserID = app.attributes.userID;
        }
        let displayMode = "";
        const search = getUrlSearchParam(location.search, "display");
        if (search) {
          displayMode = `?display=${search}`;
        } else {
          displayMode = `?display=reports`;
        }
        // Hardcoded "Email" until we have more than one medium available
        // result.data.ScheduleSettings.NotificationMediumName = "Email"
        // result.data.ScheduleSettings.NotificationMediumID = "5f00e51f-37e2-4e6c-b892-9fa92990c759"
        setDisplay(displayMode);
        // setScheduleSettings(result.data.ScheduleSettings)
        if (result.data.ScheduleSettings.length === 0) {
          updateScheduleIsCollapsed(true);
        }

        formatColumnFieldsFromDB(result.data);

        // reverse the date validation
        result.data.Filters = result.data.Filters.map((filter: any) => {
          if (
            filter.FieldID === CoreFields.Action.FirstActionDate ||
            filter.FieldID === CoreFields.Action.InstanceDueDate ||
            isTimestampField(filter.FieldID, result.data.ObjectTypeName)
          ) {
            return {
              ...filter,
              ReportFilterValueDATETIME: moment
                .utc(filter.ReportFilterValueDATETIME)
                .subtract(24, "hours")
                .toDate(),
            };
          }
          return filter;
        });

        // here we will check if we have something in local storage, if we do, we will enter it as data and set the page on Editting mode. Otherwise we will persue as normal
        const storedValues = window.localStorage.getItem(
          `${window.location.href}`,
        );
        if (
          storedValues &&
          storedValues !== JSON.stringify(result.data) &&
          result.data.ObjectTypeID === JSON.parse(storedValues).ObjectTypeID &&
          result.data.ReportID === JSON.parse(storedValues).ReportID
        ) {
          setData(JSON.parse(storedValues));
          setPageStatus(reportID === "new" ? "New" : "Editing");
        } else {
          setData(result.data);
          setPageStatus(reportID === "new" ? "New" : "Ready");
        }
      }
    };

    if (auth.isLoggedIn) {
      fetchReport();
    }
  }, [reportID]); // eslint-disable-line

  const fetchGeneratedData = async () => {
    try {
      await getPaginated(
        `report/${reportID}/generate`,
        2000,
        updateGeneratedResults,
      );
      updateDetailsCollapsed(true);
      updateGeneratedDataIsCollapsed(false);
    } catch (err) {
      setPageStatus("Ready");
      displayToast({
        status: "error",
        title: "Failed to generate Report",
      });
    }
  };

  let interimResults: GeneratedReportRow[] = [];
  const updateGeneratedResults = (responseData: any, status: RequestStatus) => {
    if (status !== "Error") {
      interimResults = combineReportResults(
        interimResults,
        responseData.reportRows,
      );
      const grouped = groupReport(
        JSON.parse(JSON.stringify(interimResults)),
        data!.Columns,
        data!.GroupByID,
      );
      const sortedResults = sortReport(grouped, data!.Columns);
      setGeneratedData({
        results: sortedResults,
        timestamp: generatedData
          ? generatedData.timestamp
          : responseData.timestamp,
      });
      if (interimResults.length === responseData.reportRows.length) {
        updateGeneratedDataIsCollapsed(false);
      }
    } else {
      displayToast({
        status: "error",
        title: "Failed to generate Report",
      });
    }
    setPageStatus(status);
  };

  const isDataReady = data && pageStatus !== "Loading";

  const pageTitle = isDataReady
    ? pageStatus !== "New"
      ? `${data.ReportName}`
      : `New ${data.ObjectTypeName} Report`
    : pageStatus !== "New"
    ? ""
    : "New Report";

  const pageSubtitle = isDataReady ? data.ReportReadableID : "";

  const breadcrumbs = isDataReady
    ? [
        {
          label: "Reports Dashboard",
          link: "/report?display=reports",
        },
      ]
    : [];

  return (
    <LegacyScreenContainer
      pageTitle={pageTitle}
      pageSubtitle={pageSubtitle}
      breadcrumbs={breadcrumbs}>
      <>
        {data && pageStatus !== "Loading" ? (
          <>
            {/* <Header
              breadcrumbs={[]}
              title={""}
              subtitle={data.ReportReadableID}
              buttonProps={{}}
              template={false}
            /> */}

            <section className="card">
              <Row>
                <Col sm="auto">
                  <h1>Details</h1>
                </Col>
                <Col>
                  <span />
                </Col>
                {pageStatus === "Ready" ? (
                  <>
                    <Col sm="auto">
                      {Access.checkAccess(
                        app.permissions_LEGACY,
                        "REPORTS",
                        Permissions.TypeCreate,
                        app.attributes.userID === data.ReportResponsibleUserID,
                      ) ? (
                        <Button
                          type="button"
                          variant="outline-dark"
                          onClick={() => {
                            setData({
                              ...data,
                              ReportResponsibleUserID: app.attributes.userID,
                            }); // here change the responsible user to the one that pressed copy
                            setCopyMode(true);
                            updateDetailsCollapsed(false);
                          }}>
                          <Copy className="feather" size="16" />
                          Copy
                        </Button>
                      ) : (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="not-authorised-to-edit">
                              You are not authorised to copy this report
                            </Tooltip>
                          }>
                          <Button
                            type="button"
                            variant="outline-dark"
                            className="disabled">
                            <Copy className="feather" size="16" />
                            Copy
                          </Button>
                        </OverlayTrigger>
                      )}
                    </Col>
                    <Col sm="auto">
                      {Access.checkAccess(
                        app.permissions_LEGACY,
                        "REPORTS",
                        Permissions.TypeUpdate,
                        app.attributes.userID === data.ReportResponsibleUserID,
                      ) ? (
                        <Button
                          type="button"
                          variant="outline-dark"
                          onClick={() => {
                            setPageStatus("Editing");
                            updateDetailsCollapsed(false);
                          }}>
                          <Edit2 className="feather" size="16" />
                          Edit
                        </Button>
                      ) : (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="not-authorised-to-edit">
                              You are not authorised to edit this
                            </Tooltip>
                          }>
                          <Button
                            type="button"
                            variant="outline-dark"
                            className="disabled">
                            <Edit2 className="feather" size="16" />
                            Edit
                          </Button>
                        </OverlayTrigger>
                      )}
                    </Col>
                  </>
                ) : null}
                {pageStatus === "Editing" ? (
                  <>
                    <Col sm="auto">
                      {Access.checkAccess(
                        app.permissions_LEGACY,
                        "REPORTS",
                        Permissions.TypeDelete,
                        app.attributes.userID === data.ReportResponsibleUserID,
                      ) ? (
                        <DropdownButton
                          id="dropdown-basic-button"
                          variant="outline-dark"
                          title="Delete">
                          <Dropdown.Item
                            onClick={() => {
                              deleteReport(data);
                            }}>
                            Confirm delete
                          </Dropdown.Item>
                        </DropdownButton>
                      ) : (
                        <OverlayTrigger
                          placement="auto"
                          overlay={
                            <Tooltip id="not-authorised-to-edit">
                              You are not authorised to delete this
                            </Tooltip>
                          }>
                          <Button
                            type="button"
                            variant="outline-dark"
                            className="disabled">
                            Delete
                          </Button>
                        </OverlayTrigger>
                      )}
                    </Col>
                  </>
                ) : null}
                <Col sm="auto">
                  <CollapseChevron
                    collapsed={isDetailsCollapsed}
                    updateCollapsed={updateDetailsCollapsed}
                  />
                </Col>
              </Row>
              <Collapse in={!isDetailsCollapsed}>
                <Row>
                  <Col>
                    <hr />
                    <Formik
                      enableReinitialize
                      initialValues={data}
                      validate={(values: ReportPageData) =>
                        validationSchema
                          .validate(values, { abortEarly: false })
                          .catch((err: any) => {
                            throw yupToFormErrors(err);
                          })
                      }
                      onSubmit={async (values, actions) => {
                        // this is a bit confusing so i'll explain
                        // in the normal state app we want to not continue with the submit if we have choose no parent or if we do not have a valid date
                        // in the template mode we want to continue without a date as it is not important and without parents if chosen to be a standAlone report
                        const ot = values.ObjectTypes.find(
                          (ot: any) => ot.ObjectTypeID === values.ObjectTypeID,
                        ).ObjectTypeName;
                        if (
                          values.Columns.length < 1 ||
                          values.OrderBy.length < 1 ||
                          !valideFilters(values.Filters)
                        ) {
                          actions.setSubmitting(false);
                        } else {
                          try {
                            updateGeneratedDataIsCollapsed(true);
                            setData(values);
                            setPageStatus("Submitting");
                            values.Filters = values.Filters.length
                              ? values.Filters.map((filter) => {
                                  if (
                                    filter.FieldID ===
                                      CoreFields.Action.FirstActionDate ||
                                    filter.FieldID ===
                                      CoreFields.Action.InstanceDueDate
                                  ) {
                                    return {
                                      ...filter,
                                      ReportFilterValueDATETIME: validateDate(
                                        filter.ReportFilterValueDATETIME,
                                        moment.tz.guess(),
                                      ),
                                    };
                                  }
                                  if (
                                    isTimestampField(
                                      filter.FieldID,
                                      data.ObjectTypeName,
                                    )
                                  ) {
                                    return {
                                      ...filter,
                                      ReportFilterValueDATETIME: moment
                                        .utc(filter.ReportFilterValueDATETIME)
                                        .add(24, "hours")
                                        .format("YYYY-MM-DDTHH:mm:ss.SSS"),
                                    };
                                  }
                                  return filter;
                                })
                              : values.Filters;

                            let results: any = {};
                            if (reportID === "new" || isCopyMode) {
                              setCopyMode(false);

                              results = await put(`report`, values, auth);
                            } else {
                              results = await post(
                                `report/${reportID}`,
                                values,
                                auth,
                              );
                            }
                            // setData(values)
                            displayToast({
                              status: "success",
                              title: `${ot} Report ${
                                reportID === "new" || isCopyMode
                                  ? "created"
                                  : "updated"
                              } successfully`,
                            });
                            // remove the local storage after submit
                            window.localStorage.removeItem(
                              `${window.location.href}`,
                            );
                            setPageStatus("Ready");
                            if (reportID === "new" || isCopyMode) {
                              navigate(
                                {
                                  pathname: `/report/${results.data.ReportID}`,
                                  search: display,
                                },
                                {
                                  state: {
                                    hasChanged: true,
                                    display: display
                                      ? getUrlSearchParam(
                                          location.search,
                                          "display",
                                        )
                                      : "",
                                  },
                                },
                              );
                            } else {
                              navigate(
                                {
                                  pathname: `/report/${data.ReportID}`,
                                  search: display,
                                },
                                {
                                  state: {
                                    hasChanged: true,
                                    display: display
                                      ? getUrlSearchParam(
                                          location.search,
                                          "display",
                                        )
                                      : "",
                                  },
                                },
                              );
                              window.location.reload();
                            }
                          } catch (err) {
                            displayToast({
                              status: "error",
                              title: `Failed to ${
                                reportID === "new" ? "create" : "update"
                              } ${
                                values.ObjectTypeName
                                  ? values.ObjectTypeName
                                  : ot
                              } Report`,
                            });
                            reportID === "new"
                              ? setPageStatus("New")
                              : setPageStatus("Editing");
                          }
                        }

                        actions.setSubmitting(false);
                      }}
                      render={(formikProps) => {
                        // here we save at every change in local storage if we are in New or Editing state
                        if (
                          (pageStatus === "New" || pageStatus === "Editing") &&
                          reportID === formikProps.values.ReportID
                        ) {
                          LocalStorage.saveInLocalStorage(window, formikProps);
                        }
                        return (
                          <Form onSubmit={formikProps.handleSubmit}>
                            {pageStatus === "New" || isCopyMode ? (
                              <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                  Report Type
                                </Form.Label>
                                <Col sm="9">
                                  <Select
                                    onUpdate={(e) => {
                                      navigate(
                                        `/report/new?type=${e.value}${
                                          getUrlSearchParam(
                                            location.search,
                                            "parentid",
                                          )
                                            ? `&parentid=${getUrlSearchParam(
                                                location.search,
                                                "parentid",
                                              )}`
                                            : ""
                                        }`,
                                      );
                                    }}
                                    name="ReportTypeID"
                                    value={formikProps.values.ObjectTypeID}
                                    optionsList={formikProps.values.ObjectTypes}
                                    optionsListID="ObjectTypeID"
                                    optionsListValue="ObjectTypeName"
                                    readOnly={pageStatus !== "New"}
                                    readOnlyValue={
                                      formikProps.values.ObjectTypeName
                                    }
                                  />
                                </Col>
                              </Form.Group>
                            ) : (
                              <Form.Group as={Row}>
                                <Form.Label column sm="2">
                                  Report Type
                                </Form.Label>
                                <Col sm="9">
                                  <Text
                                    value={formikProps.values.ObjectTypeName}
                                    name="ReportObjectType"
                                    readOnly
                                  />
                                </Col>
                              </Form.Group>
                            )}

                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Name
                              </Form.Label>
                              <Col sm="9">
                                <Text
                                  value={formikProps.values.ReportName}
                                  name="ReportName"
                                  onUpdate={formikProps.handleChange}
                                  readOnly={
                                    pageStatus !== "Editing" &&
                                    pageStatus !== "New" &&
                                    !isCopyMode
                                  }
                                  showCharacterLimit={
                                    pageStatus === "New" ||
                                    pageStatus === "Editing" ||
                                    isCopyMode
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Description
                              </Form.Label>
                              <Col sm="9">
                                <Textarea
                                  value={formikProps.values.ReportDescription}
                                  name="ReportDescription"
                                  onUpdate={(val: any) => {
                                    formikProps.setFieldValue(
                                      "ReportDescription",
                                      val,
                                    );
                                  }}
                                  readOnly={
                                    pageStatus !== "Editing" &&
                                    pageStatus !== "New" &&
                                    !isCopyMode
                                  }
                                  showCharacterLimit={
                                    pageStatus === "New" ||
                                    pageStatus === "Editing" ||
                                    isCopyMode
                                  }
                                  richText
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Responsible User
                              </Form.Label>
                              <Col sm="9">
                                <Select
                                  onUpdate={(e: any) => {
                                    formikProps.setValues({
                                      ...formikProps.values,
                                      ReportResponsibleUserID: e.value,
                                    });
                                  }}
                                  name="ReportResponsibleUserID"
                                  value={
                                    formikProps.values.ReportResponsibleUserID
                                  }
                                  unselectedOption="Select a Value"
                                  optionsList={formikProps.values.Users}
                                  optionsChild={(listItem: any) => ({
                                    value: listItem.UserID,
                                    label: `${listItem.UserFirstName} ${listItem.UserLastName}`,
                                  })}
                                  readOnly={
                                    pageStatus !== "Editing" &&
                                    pageStatus !== "New" &&
                                    !isCopyMode
                                  }
                                  readOnlyValue={
                                    formikProps.values.ReportResponsibleUserName
                                  }
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                              <Form.Label column sm="2">
                                Visible to
                              </Form.Label>
                              <Col sm="9">
                                <MultiSelect
                                  onUpdate={(e: any) => {
                                    const allOptions = getVisibilityOptions(
                                      formikProps.values,
                                      formikProps.values
                                        .ReportResponsibleUserID,
                                    );
                                    if (e !== null) {
                                      const newVisibility = e.map(
                                        (element: any) => {
                                          const permission = {
                                            ID: element.value,
                                            Name: element.label,
                                            Type: allOptions.filter(
                                              (opt: any) =>
                                                opt.ID === element.value,
                                            )[0].Type,
                                          };
                                          return permission;
                                        },
                                      );
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        VisibilityIDs: newVisibility,
                                      });
                                    } else {
                                      const newVisibility: any[] = [];
                                      formikProps.setValues({
                                        ...formikProps.values,
                                        VisibilityIDs: newVisibility,
                                      });
                                    }
                                  }}
                                  name="VisibilityID"
                                  value={formikProps.values.VisibilityIDs}
                                  optionsListID="ID"
                                  optionsListValue="Name"
                                  optionsList={getVisibilityOptions(
                                    formikProps.values,
                                    formikProps.values.ReportResponsibleUserID,
                                  )}
                                  optionsChild={false}
                                  readOnly={
                                    pageStatus !== "Editing" &&
                                    pageStatus !== "New" &&
                                    !isCopyMode
                                  }
                                  readOnlyComponent={
                                    formikProps.values.VisibilityIDs.length > 0
                                      ? formikProps.values.VisibilityIDs
                                      : undefined
                                  }
                                  readOnlyValue={
                                    formikProps.values.VisibilityIDs.length > 0
                                      ? undefined
                                      : " "
                                  }
                                  display={display}
                                  unselectedOption="Select a Value"
                                  visibility
                                />
                              </Col>
                            </Form.Group>
                            {pageStatus !== "New" &&
                            !isCopyMode &&
                            pageStatus !== "Editing" &&
                            pageStatus !== "Submitting" ? (
                              <History
                                ID={data.ReportID}
                                authState={auth}
                                lastModifiedTs={data.ModifiedTs}
                                lastModififedUser={data.ModifiedUserName}
                              />
                            ) : null}
                            <Form.Group as={Row}>
                              <SettingsFilters
                                key="SettingsFilters"
                                handleChange={(e: any) => {
                                  formikProps.setValues({
                                    ...formikProps.values,
                                    Columns: e.columnValue,
                                    OrderBy: e.orderBy,
                                    GroupByID: e.groupBy,
                                    Filters: e.filters,
                                    ReportLatestInstanceOnly: e.latestInstance,
                                  });
                                }}
                                id="SettingsFilters"
                                columnFields={formatFields(
                                  formikProps.values.CustomFields,
                                  formikProps.values.CoreFields,
                                  formikProps.values.ObjectTypeID,
                                )}
                                columnValue={formikProps.values.Columns}
                                readOnly={
                                  pageStatus !== "Editing" &&
                                  pageStatus !== "New" &&
                                  !isCopyMode
                                }
                                display={display}
                                orderBy={formikProps.values.OrderBy}
                                groupBy={formikProps.values.GroupByID}
                                filters={formikProps.values.Filters}
                                reset={data.SettingsFiltersReset}
                                pageStatus={pageStatus}
                                initialData={data}
                                latestInstance={
                                  pageStatus === "New"
                                    ? false
                                    : formikProps.values
                                        .ReportLatestInstanceOnly
                                }
                                allFields={formikProps.values.CoreFields.concat(
                                  formikProps.values.CustomFields,
                                )}
                              />
                            </Form.Group>
                            <Form.Group as={Row}>
                              {pageStatus === "Editing" ||
                              pageStatus === "New" ||
                              isCopyMode ? (
                                <>
                                  <Col sm={{ span: "auto", offset: "2" }}>
                                    <Button
                                      type="submit"
                                      onClick={() => {
                                        const extraErrors = [];
                                        if (
                                          formikProps.values.Columns.length < 1
                                        ) {
                                          extraErrors.push(
                                            '"At least one column is required."',
                                          );
                                        }
                                        if (
                                          !valideFilters(
                                            formikProps.values.Filters,
                                          )
                                        ) {
                                          extraErrors.push(
                                            '"Filters must be complete."',
                                          );
                                        }
                                        if (
                                          formikProps.values.OrderBy.length < 1
                                        ) {
                                          extraErrors.push(
                                            `"At least one Order By is required"`,
                                          );
                                        }
                                        let errors = JSON.stringify(
                                          formikProps.errors,
                                        )
                                          .replace("{", "")
                                          .replace("}", "")
                                          .split(",");
                                        errors.map(
                                          (e: string, index: number) =>
                                            (errors[index] = e
                                              .substring(e.lastIndexOf(":") + 1)
                                              .replace("}", "")),
                                        );
                                        errors = errors.concat(extraErrors);
                                        if (
                                          (errors.length > 0 &&
                                            errors[0] !== "") ||
                                          (errors.length > 1 &&
                                            errors[0] === "")
                                        ) {
                                          const title = `Failed to ${
                                            reportID === "new" || isCopyMode
                                              ? "create"
                                              : "update"
                                          } ${
                                            formikProps.values.ObjectTypeName
                                          } Report`;
                                          const description = `${errors
                                            .map((error: string) =>
                                              error
                                                .replace("[", "")
                                                .replace("]", "")
                                                .replace("}", "")
                                                .replace("{", ""),
                                            )
                                            .filter(
                                              (error: any) => error !== "null",
                                            )
                                            .join("\n")}`;
                                          displayToast({
                                            status: "error",
                                            title,
                                            description,
                                          });
                                        } else if (
                                          formikProps.values.ReportName ===
                                            "" &&
                                          formikProps.values
                                            .ReportDescription === "" &&
                                          formikProps.isSubmitting === false
                                        ) {
                                          errors.push(
                                            `Name is required.`,
                                            `Description is required.`,
                                          );
                                          const title = `Failed to ${
                                            pageStatus === "New"
                                              ? "create"
                                              : "update"
                                          } ${
                                            formikProps.values.ObjectTypeName
                                          } Report`;
                                          const description = `${errors.join(
                                            "\n",
                                          )}`;
                                          displayToast({
                                            status: "error",
                                            title,
                                            description,
                                          });
                                        }
                                      }}>
                                      Submit
                                    </Button>
                                  </Col>
                                  <Col sm="auto">
                                    <Button
                                      type="button"
                                      variant="light"
                                      onClick={() => {
                                        // remove the local storage after cancel
                                        window.localStorage.removeItem(
                                          `${window.location.href}`,
                                        );
                                        if (pageStatus === "Editing") {
                                          setData({
                                            ...data,
                                            SettingsFiltersReset:
                                              !data.SettingsFiltersReset,
                                          });
                                          formikProps.resetForm();
                                          window.location.reload(); // to eliminate any saved state of the settings filters
                                          setPageStatus("Ready");
                                        } else if (isCopyMode) {
                                          setCopyMode(false);
                                          setPageStatus("Ready");
                                          navigate(
                                            {
                                              pathname: `/report/${data.ReportID}`,
                                              search: display,
                                            },
                                            {
                                              state: {
                                                hasChanged: true,
                                                display: display
                                                  ? getUrlSearchParam(
                                                      location.search,
                                                      "display",
                                                    )
                                                  : "",
                                              },
                                            },
                                          );
                                        } else {
                                          navigate("/report");
                                        }
                                      }}>
                                      Cancel
                                    </Button>
                                  </Col>
                                </>
                              ) : pageStatus !== "Submitting" && !isCopyMode ? (
                                <Col>
                                  <SpinnyButton
                                    text="Run now"
                                    variant="primary"
                                    onClick={() => fetchGeneratedData()}
                                  />
                                </Col>
                              ) : null}
                            </Form.Group>
                          </Form>
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Collapse>
            </section>
            {/* adding the scheduling card */}
            {pageStatus === "Ready" || pageStatus === "Editing" ? (
              <ScheduleReport
                data={data}
                collapse={data.ScheduleSettings.length === 0 || generatedData}
              />
            ) : null}
            {data && generatedData && !isCopyMode ? (
              <>
                <section className="card">
                  <Row>
                    <Col>
                      <h1>{data.ReportName}</h1>
                    </Col>
                    <Col sm="auto">
                      <SpinnyButton
                        text="Refresh"
                        onClick={() => fetchGeneratedData()}
                      />
                    </Col>
                    <Col sm="auto">
                      <>
                        <Button
                          variant="outline-dark"
                          onClick={() => setDialogVisible(true)}>
                          Email
                        </Button>
                        <Dialog
                          show={dialogVisible}
                          hide={() => setDialogVisible(false)}
                          title="Email Report"
                          headerStyle={{ borderBottom: "0px" }}
                          footerStyle={{ borderTop: "0px" }}
                          bodyComponent={
                            <Row>
                              <Col sm="12">
                                <p style={{ padding: "0 16px" }}>
                                  An email will be sent to you with the contents
                                  of this report
                                </p>
                              </Col>
                            </Row>
                          }
                          confirm={() => {
                            sendEmail(
                              data,
                              generatedData.results,
                              auth,
                              displayToast,
                            );
                          }}
                        />
                      </>
                      {/* <Button variant={'outline-dark'}>Email</Button> */}
                    </Col>
                    <Col sm="auto">
                      <Button
                        variant="outline-dark"
                        onClick={() => {
                          const win = window.open(
                            `/print/report/${data.ReportID}`,
                            "_blank",
                          );
                          if (win != null) {
                            win.focus();
                          }
                        }}>
                        Print
                      </Button>
                    </Col>
                    <Col sm="auto">
                      <DropdownButton
                        id="dropdown-basic-button"
                        variant="outline-dark"
                        title="Export">
                        <CSVLink
                          data={getTableData(generatedData.results, "csv")}
                          filename={`${data.ReportName}.csv`}>
                          .csv
                        </CSVLink>
                        {/* <Dropdown.Item > .pdf </Dropdown.Item>
                                            <Dropdown.Item > .docx </Dropdown.Item>
                                            <Dropdown.Item > .xlsx </Dropdown.Item> */}
                      </DropdownButton>
                      {/* <Button variant={'outline-dark'}> Export</Button> */}
                    </Col>
                    <Col sm={{ span: "auto" }}>
                      <CollapseChevron
                        collapsed={generatedDataIsCollapsed}
                        updateCollapsed={updateGeneratedDataIsCollapsed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h1 className="page-title" style={{ fontSize: "15px" }}>
                        {moment(generatedData.timestamp)
                          .local()
                          .format("DD-MMM-YYYY HH:mm")}
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {data.ObjectTypeName}s Report:
                      {displayFilters(
                        data.Filters,
                        data.Columns,
                        data.CustomFields.concat(data.CoreFields),
                      )}
                    </Col>
                  </Row>
                  <Collapse in={!generatedDataIsCollapsed}>
                    <Row>
                      <Col>
                        <hr />
                        {generatedData.results.tables.length > 0 ? (
                          <>
                            {displayReport(generatedData.results)}
                            {pageStatus === "Fetching" ? <Loading /> : null}
                          </>
                        ) : (
                          <Row>
                            <Col>No records returned.</Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Collapse>
                </section>
              </>
            ) : null}
          </>
        ) : pageStatus !== "Error" ? (
          <>
            <div className="progress-spinner" style={{ marginTop: "20%" }}>
              <Loading size={"xl"} />
            </div>
          </>
        ) : (
          <>
            <Card2 style={{ marginTop: "20%", textAlign: "center" }}>
              <Row style={{ marginBottom: "20px" }}>
                <Col>
                  <Frown />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1>
                    Oops! It looks like this report is not available - either
                    you don&apos;t have permission to view it, or it
                    doesn&apos;t exist.
                  </h1>
                </Col>
              </Row>
            </Card2>
          </>
        )}
      </>
    </LegacyScreenContainer>
  );
};

const sendEmail = async (
  data: any,
  content: any,
  authState: any,
  displayToast: DisplayToastFn,
) => {
  let text = "";
  data.Filters.map((filter: any, index: number) => {
    let conditionText = ``;
    const column = data.Columns.find(
      (col: any) => col.ReportColumnID === filter.ReportColumnID,
    );
    if (column) {
      const value = findNotNull([
        filter.ReportFilterValueINT,
        filter.ReportFilterValueDATETIME,
        filter.ReportFilterValueURL,
        filter.ReportFilterValueVARCHAR,
      ]);
      conditionText += `${
        index === 0 ? "" : filter.ReportFilterJoiningOperator
      } ${column.Name} ${filter.ReportFilterValueOperator} ${
        filter.ReportFilterValueOperator === "exists"
          ? ""
          : column.Name === "Time Note Billable"
          ? value === 1
            ? "Billable"
            : "Nonbillable"
          : value
      } `;
      text = `${text}${conditionText}`;
    }
  });

  content.totalBodyRowsLength = 0;
  content.tables.forEach((table: any) => {
    content.totalBodyRowsLength += table.bodyRows.length;
    table.bodyRowsLength = table.bodyRows.length;
  });
  if (content.totalBodyRowsLength > 1500) {
    let numberOfRowsToSplice = content.totalBodyRowsLength - 1500;
    for (
      let i = content.tables.length - 1;
      i >= 0 && numberOfRowsToSplice > 0;
      i -= 1
    ) {
      const removedRows = content.tables[i].bodyRows.splice(
        0,
        numberOfRowsToSplice,
      );
      numberOfRowsToSplice -= removedRows.length;
    }
  }

  const emailData = {
    reportReadableID: data.ReportReadableID,
    reportName: data.ReportName,
    reportID: data.ReportID,
    text: `${data.ObjectTypeName}s Report${text === "" ? "" : ": "}${text}`,
    tableData: content,
  };
  const result = await put(`report/email`, emailData);
  if (result.status === 200 || !result.data) {
    displayToast({
      status: "success",
      title: `Email sent successfully`,
    });
  } else {
    displayToast({
      status: "error",
      title: "Failed to send email",
    });
  }
};

const getTableData = (content: any, purpose: any) => {
  if (purpose === "csv") {
    return content.tables.length > 0
      ? content.tables
          .map((table: any) =>
            [
              typeof table.heading === "string"
                ? table.heading
                : table.groupValue,
              ...table.headerRows.map((row: any) =>
                row.columns
                  .map((col: any) => `"${col.text.replace('"', '""')}"`)
                  .join(","),
              ),
              ...table.bodyRows.map((row: any) =>
                row.columns
                  .map((col: any) =>
                    col.fieldDataType === "DATETIME"
                      ? formatCellValue(col)
                      : col.valueText
                      ? `"${stripHtml(col.valueText.toString()).replace(
                          '"',
                          '""',
                        )}"`
                      : "",
                  )
                  .join(","),
              ),
            ].join("\n"),
          )
          .join("\n")
      : "No records returned";
  }

  return [];
};

const isTimestampField = (fieldID: string, objectTypeGroupName: any) => {
  const coreFieldKey: keyof typeof CoreFields = objectTypeGroupName;
  return (
    fieldID === CoreFields.Action.InstanceResponseDate ||
    fieldID === CoreFields[coreFieldKey].TimeNoteDate ||
    fieldID === CoreFields[coreFieldKey].NoteCreationDate ||
    fieldID === CoreFields[coreFieldKey].TimeNoteCreationDate
  );
};

export { ScreensReport };
