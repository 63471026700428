import React, { useRef } from "react";
import { chakra, Tooltip, Text } from "@chakra-ui/react";
import RichText from "../../form/RichText";
import stringStripHtml from "string-strip-html";

type Props = {
  description?: string | null;
};

const TableDescriptionField = ({ description }: Props) => {
  const stripped = description ? stringStripHtml(description) : "";

  return (
    <Tooltip
      bg="gray.50"
      color="black"
      label={
        <RichText onChange={() => {}} isReadOnly value={description || ""} />
      }>
      <chakra.span
        whiteSpace="nowrap"
        display="inline-block"
        textOverflow="ellipsis"
        overflow="hidden"
        maxW="64">
        {stripped}
      </chakra.span>
    </Tooltip>
  );
};

export default TableDescriptionField;
