import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { formatDistance } from "date-fns";
import { RiArrowRightLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { formatUtcDateReadableLong } from "../../../../utilities/dateUtils";
import { ManualData, ManualSectionSummary } from "../../../../shared/v2/definitions/manuals";

type Props = {
  manual: ManualData & { sections: ManualSectionSummary[] };
};

export function ManualCard({ manual }: Props) {
  return (
    <LinkBox
      _hover={{
        boxShadow: "sm",
        borderColor: "brand.500",
        "& .arrow-icon": {
          transform: "translate(3px, 0)",
          color: "brand.500",
          opacity: 1.0,
        },
        "& .heading-text": {
          color: "brand.500",
        },
      }}
      as={Card}
      variant="outline">
      <CardHeader>
        <LinkOverlay
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          as={Link}
          to={`/manual/${manual.manualID}`}>
          <Heading
            transitionProperty="common"
            transitionDuration="fast"
            className="heading-text"
            size="md"
            alignItems="center"
            display="flex">
            {manual.name}
          </Heading>
          <Icon
            transitionProperty="common"
            transitionDuration="fast"
            color="black"
            opacity="0.0"
            ml="1"
            boxSize="5"
            transition=""
            className="arrow-icon"
            as={RiArrowRightLine}
          />
        </LinkOverlay>
      </CardHeader>
      <CardBody>
        {manual.sections.map((section) => (
          <Text key={section.manualSectionID}>{section.title}</Text>
        ))}
      </CardBody>
      <CardFooter>
        <HStack zIndex={1} justifyContent="end" w="full">
          <Tooltip
            shouldWrapChildren
            label={`Last Modified: ${formatUtcDateReadableLong(
              new Date(manual.modifiedTs),
            )}`}>
            <Text fontSize="xs" color="gray.500">
              Last Modified:{" "}
              {formatDistance(new Date(manual.modifiedTs), Date.now())} ago
            </Text>
          </Tooltip>
        </HStack>
      </CardFooter>
    </LinkBox>
  );
}
